<template>
	<nav >
		<v-app-bar fixed class="primary white--text">
		<!-- <v-toolbar fixed flat class="primary white--text ma-0"> -->
			<v-app-bar-nav-icon v-if="!goBackLink" class="white--text hidden-md-and-up" @click="drawer=!drawer"></v-app-bar-nav-icon>
			<div class="appBarInfo">
				<v-btn v-if="goBackLink" icon class="mr-1" @click="goBack()">
					<v-icon class="white--text">mdi-arrow-left</v-icon>
				</v-btn>
				<v-toolbar-title class="toolbarTitle">{{title}}</v-toolbar-title>
			</div>
		<!-- </v-toolbar> -->
		</v-app-bar>

		<v-navigation-drawer disable-resize-watcher v-model="drawer" app :permanent="$vuetify.breakpoint.mdAndUp">
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title>{{mutableUser}}</v-list-item-title>
						<v-menu
						v-model="showMenu"
						offset-y
						:close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-list-item-subtitle
								v-bind="attrs"
								v-on="on"
								class="mt-2"
								style="display: flex; justify-content: space-between;">
									{{selectedTenant.name}}
									<v-icon v-if="!showMenu">mdi-menu-down</v-icon>
									<v-icon v-else>mdi-menu-up</v-icon>
								</v-list-item-subtitle>
							</template>
							<v-list>
								<v-list-item>
									<v-list-item-icon class="mr-3 my-2">
										<v-icon>mdi-account</v-icon>
									</v-list-item-icon>
									<v-list-item-content class="py-2">
										<v-list-item-title>{{mutableUsermail}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-content class="py-2">
										<v-list-item-title>{{lviews.companies}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>

								<!-- Tenants list -->
								<v-list-item
								v-for="tenant in tenantsList"
								:key="tenant.slug"
								:class="tenant.selected? 'companyItemSelected': 'companyItem'"
								>
									<v-list-item-icon class="mr-3 my-2">
										<v-icon>mdi-briefcase-variant</v-icon>
									</v-list-item-icon>
									<v-list-item-content class="py-2" @click="changeTenant(tenant)">
										<v-list-item-title>{{ tenant.name }}</v-list-item-title>
									</v-list-item-content>
									<!-- <v-list-item-action
									@click="selectAsFavourite(tenant)"
									>
										<v-icon v-if="tenant.favourite" color="primary">mdi-star</v-icon>
										<v-icon v-else class="companyItemAction">mdi-star-outline</v-icon>
									</v-list-item-action> -->
								</v-list-item>
								<v-divider></v-divider>

								<v-list-item 
								v-for="item in menuModules" 
								:key="item.name"
								@click="onItemClick(item)">
									<v-list-item-icon class="mr-3 my-2">
										<v-icon color="primary">{{item.icon}}</v-icon>
									</v-list-item-icon>
									<v-list-item-content class="py-2">
										<v-list-item-title>{{item.name}}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-divider class="primary"></v-divider>

			<v-list dense nav>
				<v-list-item-group v-model="selectedMenuItem">
					<div
						v-for="(item, i) in displayedModules"
						v-bind:key="i"
						@click="onItemClick(item)"
					>
						<!-- normal menu item -->
						<v-list-item>
							<v-list-item-icon
								v-if="typeof(item.icon) !== 'undefined' && item.icon != null"
							>
								<v-icon color="primary">{{ item.icon }}</v-icon>
							</v-list-item-icon>

							<v-list-item-content>
								{{ item.name }}
							</v-list-item-content>

							<v-list-item-icon
								v-if="typeof(item.rightIcon) !== 'undefined' && item.rightIcon != null"
							>
								<v-icon>{{ item.rightIcon }}</v-icon>
							</v-list-item-icon>
						</v-list-item>

						<!-- subitem menu -->
						<template v-if="item.type == 'DROPDOWN' && item.opened">
							<div v-for="(subItem,si) in item.dropdownItems" 
								v-bind:key="si"
								@click="onSubItemClick(item, subItem)"
							>
								<v-list-item class="ml-8">
									<v-list-item-icon v-if="typeof(subItem.icon) !== 'undefined' && subItem.icon != null">
										<v-icon color="primary">{{ subItem.icon }}</v-icon>
									</v-list-item-icon>
									<v-list-item-content>{{ subItem.name }}</v-list-item-content></v-list-item>
							</div>
							<v-divider></v-divider>
						</template>	
					</div>
				</v-list-item-group>
			</v-list>
			
			<template v-slot:append>
				<div class="logo_wrapper">
					<v-img
					:src="require('../assets/logo_clock.svg')"
					contain
					width="80%"
					/>
				</div>
			</template>
		</v-navigation-drawer>
	</nav>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {
	name: 'Navbar',

	components: {
	},

	props: {
		title: String,
		open: Boolean,
		goBackLink: String,
		goBackDisabled: {
			type: Boolean,
			default: false
		},
		user: String,
		usermail: String,
		tenants: [],
		currentTenant: Object,

		// If not provided, full permissions assumed
		permissions: {
			type: Array,
			required: false,
			default: null
		}
	},

	computed: {
		lviews: { get: function () { return this.$t('app.navbar'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },
	},

	data: function() {
		return {
			drawer: false,
			showMenu: false,
			mutableUser: this.user,
			mutableUsermail: this.usermail,

			// Modules displayed to user
			displayedModules: [],

			selectedMenuItem: 0,
			selectedTenant: {
				slug: 1,
				name: "Company 1",
				favourite: true,
				selected: true,
				action: null,
			},	
			modules: [
				{
					permission: "term_summary_view",
					name: "Timesheets",
					icon: "mdi-calendar",
					link: '/timesheets', // Link has priority over action
					type: "OPTION",
				},
				{
					permission: "term_projects_view",
					name: "Current Presence",
					icon: "mdi-clock-time-nine-outline",
					link: '/presence',
					type: "OPTION",
				},
				{
					permission: "workers_view",
					name: "Workers",
					icon: "mdi-account-group",
					link: '/workers',
					type: "OPTION",
				},			

				{
					permission: "admin_view", // TODO to handle all subitems
					name: "Administration",
					icon: "mdi-cogs",
					rightIcon: "mdi-chevron-right",
					link: '/admin',
					type: "DROPDOWN",
					opened: false, // initially closed state
					blockItemClick: false,
					dropdownItems: [
						{
							permission: "view_user",
							name: "Users",
							icon: "mdi-account-multiple",
							link: '/admin/users',
							type: "OPTION",
						},				
						{
							permission: null, // TODO
							name: "Dictionaries",
							icon: "mdi-book",
							link: '/admin/dictionaries',
							type: "OPTION",
						},
						{
							permission: null, // TODO
							name: "Permissions",
							icon: "mdi-key-variant",
							link: '/admin/permissions',
							type: "OPTION",
						},									
					],			
				},			
				{
					permission: null,
					name: "About",
					icon: "mdi-information",
					link: '/about',
					type: "OPTION",
				},
				// {
				// 	permission: null,
				// 	name: "Settings",
				// 	icon: "mdi-cog",
				// 	link: '/settings',
				// 	type: "OPTION",
				// },
				// {
				// 	permission: null,
				// 	name: "Log out",
				// 	icon: "mdi-logout",
				// 	link: null,
				// 	action: null, // Assigned in before mount
				// 	type: "OPTION",
				// }
				// {
				// 	access: "!!!PLACEHOLDER!!!_read",
				// 	name: "Podsumowanie pracownika",
				// 	link: '/summary'
				// },
			],
			tenantsList: [
				// {
				// 	slug: "dev01",
				// 	name: "Company 1",
				// 	favourite: true,
				// 	selected: true,
				// },
			],
			menuModules: [
				{
					permission: null,
					name: "Settings",
					icon: "mdi-cog",
					link: '/settings',
					type: "OPTION",
				},
				{
					permission: null,
					name: "Log out",
					icon: "mdi-logout",
					link: null,
					action: null, // Assigned in before mount
					type: "OPTION",
				}
			],
			mutablePermissions: null
		}

	},
	beforeMount(){
		this.menuModules[this.menuModules.length-1].action = this.onLogoutClick;
		if(!this.user && !this.usermail){
			this.mutableUsermail=localStorage.usermail
			this.mutableUser=localStorage.user
		}
		if(!this.currentTenant){
			// if(localStorage.getItem('tenantName')!= null && localStorage.getItem('tenantSlug')!=null){
				this.selectedTenant={
					name: localStorage.tenantName,
					slug: localStorage.tenantSlug
				}
			// }
		}
		let mutableTenants=this.tenants? this.tenants: this.getTenants()
		if(mutableTenants){
			this.tenantsList=[]
			for(let item of mutableTenants){
				this.tenantsList.push({
					slug: item.slug,
					name: item.name,
					favourite: false,
					selected: item.slug==this.selectedTenant.slug? true: false,
				})
			}
		}
	},
	
	mounted(){
		if(localStorage.permissions){
			this.mutablePermissions = JSON.parse(localStorage.permissions);
		}
		this.modules[0].name=this.lviews.timesheets
		this.modules[1].name=this.lviews.currentPresence
		this.modules[2].name=this.lviews.workers
		this.modules[3].name=this.lviews.administration
		this.modules[3].dropdownItems[0].name=this.lviews.users
		this.modules[3].dropdownItems[1].name=this.lviews.dictionaries
		this.modules[3].dropdownItems[2].name=this.lviews.permissions
		this.modules[4].name=this.lviews.about
		this.menuModules[0].name=this.lviews.settings
		this.menuModules[1].name=this.lviews.logout

		this.reload();
	},

	watch: {
		permissions: function(){
			this.mutablePermissions=this.permissions
			this.reload();
		},
		user: function(){
			this.mutableUser=this.user
		},
		usermail: function(){
			this.mutableUsermail=this.usermail
		},
		currentTenant: function(){
			this.selectedTenant=this.currentTenant
			this.selectTenant(this.selectTenant)
		},
		tenants: function(){
			if(this.tenants){
				this.tenantsList=[]
				for(let item of this.tenants){
					this.tenantsList.push({
						slug: item.slug,
						name: item.name,
						favourite: false,
						selected: item.slug==this.selectedTenant.slug? true: false,
					})
				}
			}
		}
	},

	methods: {
		// method called by App.vue after onLogin()
		redirectDefault(){
			if (this.displayedModules.length > 0){
				this.selectedMenuItem = 0; // select the first item by default 
				this.onItemClick(this.displayedModules[0]); // click the first item
			}
			else{
				throw "Invalid permissions"
			}
		},
		getTenants(){
			let stringifiedTenants = JSON.parse(localStorage.getItem('tenants'))
			let tenants = []
			if(stringifiedTenants){
				for(let item of stringifiedTenants){
					tenants.push(JSON.parse(item))
				}
			}
			return tenants
		},
		reload(){
			this.displayedModules.splice(0, this.displayedModules.length);

			if(this.mutablePermissions == null){
				for(let i = 0; i < this.modules.length; i++){
					this.displayedModules.push(this.modules[i]);
				}
				return;
			}

			else{
				let item;
				for(let i = 0; i < this.modules.length; i++){
					item = this.modules[i];
					if(item.permission != null) {
						if(this.mutablePermissions.includes(item.permission)){
							this.displayedModules.push(item);
						}
					}
					else {
						this.displayedModules.push(item);
					}
				}
			}
		},
		onItemClick(item){
			// For 'DROPDOWN' - switch between true & false state
			if(typeof(item.type) !== 'undefined' && item.type == "DROPDOWN" && typeof(item.opened) !== 'undefined') {
				if(item.blockItemClick) {
					item.blockItemClick = false;
					return;
				} else {
					item.opened = !item.opened;
				}
			}
			// Standard redirect
			if(typeof(item.link) !== 'undefined' && item.link != null){
				if(this.$router.currentRoute.path != item.link)
					this.$router.push(item.link);
					else{
						this.$router.go()
					}
			}
			// Custom action
			else if(typeof(item.action) !== 'undefined' && item.action != null){
				item.action(item);
			}
			this.showMenu=false
		},
		onSubItemClick(item, subItem){
			// For 'DROPDOWN' - block cascaded 'onItemClick'
			if(typeof(item.type) !== 'undefined' && item.type == "DROPDOWN" && typeof(item.opened) !== 'undefined') {
				item.blockItemClick=true;
			}
			// Only standard redirect
			if(typeof(subItem.link) !== 'undefined' && subItem.link != null){
				if(this.$router.currentRoute.path != subItem.link)
					this.$router.push(subItem.link);
			}
		},		
		onLogoutClick(){
			localStorage.clear();
			this.$router.push("/");
		},
		fetchTenantPermissions: async function(tenant){
			await axios({
				method:'get',
				url: appConfig.getApiUrl(null) + "/api/v1/tenants/"+ tenant.slug+"/my-permissions/",
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}).then((response) => {
				this.mutablePermissions=this.processPermissions(response.data)
				localStorage.permissions = JSON.stringify(this.mutablePermissions)
				this.selectTenant(tenant)
				this.reload()
				this.redirectDefault()
				this.$emit("set-state", "DEFAULT");
			})
			.catch((error) => {
				this.$emit("set-state", "DEFAULT");
				// If server is unreachable it won't return a response
				// This case has to be handled
				if (typeof(error.response) === 'undefined'){
					this.$emit('display-error', this.lmessages.networkError);
					return;
				}

				switch(error.response.status){
					case 400:
						this.$emit('display-error', this.lmessages.requestRejected);
						break
					case 401: 
						this.$router.push("/");
						break
					case 429:
						this.$emit('display-error', this.lmessages.tooManyRequestsError);
						break;
					case 500:
						this.$emit('display-error', this.lmessages.internalServerError);
						break;
					default:
						this.$emit('display-error', this.lmessages.errorOccured);
				}
			});
		},
		processPermissions(permissionData){
			var item;
			var response = [];

			for(let i = 0; i < permissionData.length; i++){
				item = permissionData[i];
				response.push(item.codename);
			}

			return response;
		},
		goBack(){
			if(this.goBackDisabled){
				this.$emit('go-back-clicked');
			}
			else{
				this.$router.push(this.goBackLink)
			}
		},
		selectTenant(tenant){
			for(let item of this.tenantsList){
				if(item.slug==tenant.slug){
					item.selected=true
					this.selectedTenant=item
					localStorage.tenantName = item.name;
					localStorage.tenantSlug = item.slug;
				}
				else{
					item.selected=false
				}
			}
		},
		refreshPage(){
			this.$router.go()
		},
		changeTenant: async function(tenant){
			if(!tenant.selected){
				this.$emit("set-state", "LOADING_OVERLAYER");
				await this.fetchTenantPermissions(tenant)
			}
		},
		selectAsFavourite(tenant){
			for(let item of this.tenantsList){
				if(item.slug==tenant.slug){
					item.favourite=true
					if(this.selectedTenant.slug==item.slug){
						this.selectedTenant=item
					}
				}
				else{
					item.favourite=false
				}
			}
			//TODO - zapytanie do API
		}
	}
};
</script>

<style scoped>

.v-navigation-drawer {
	max-width: 80%;
}


	.appBarInfo {
		margin-left: 250px;
		display: flex;
		align-items: center;
	}

@media only screen and (max-width: 960px) { 
	.appBarInfo {
		margin-left: 0;
		display: flex;
		align-items: center;
	}
}



.companyItem .companyItemAction{
	visibility: hidden;
}

.companyItem:hover{
	cursor: pointer;
	background: #F5F5F5;
}

.companyItem:hover .companyItemAction{
	visibility: visible;
}

/* Wersja z zaznaczeniem wybranego tenanta za pomocą szarego tła */
.companyItemSelected{
	background: #E0E0E0;
}

.companyItemSelected:hover{
	cursor: pointer;
}

/* Wersja z zaznaczeniem wybranego tenanta za pomocą niebieskiego paska po lewej stronie */
/* .companyItem{
	border-left: 4px solid #ffffff;
}
.companyItemSelected{
	border-left: 4px solid #2962ff;
} */
</style>
